import React from 'react';

import styles from './index.module.css';

const ActivityIndicator = ({
    size = 18,
    color = '#ffffff',
    style,
}) => {
    return (
        <svg
            className={styles.ActivityIndicator}
            style={style}
            width={size}
            height={size}
            viewBox="0 0 18 18"
        >
            <circle cx="9" cy="9" r="8" stroke={color} />
        </svg>
    );
};

export default ActivityIndicator;
