import React, { useEffect } from 'react';
import Button from '../Button';
import Switch from '../Switch';
import Modal from '../Modal';
import Input from '../Input';
import styles from './index.module.css';
import payicons from '../../icons/pay.png'
import SoapRequest from '../../service';
import { useState } from 'react';

const PaymentContainer = ({paymentData, isAllValidate, urls, hotel}) => {
    
    const {
        totalSum,
        balanceAmount,
        reservationConditions,
        guestGroup,
        firstDaySum,
    } = paymentData;
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [finalChecked, setFinalChecked] = useState(false)
    const [confirmIsVisible, setConfirmIsVisible] = useState(false);
    const [email, setEmail] = useState('')
    

    const moneyToText = (money) => {
        const moneyData = money.toString().split('.')
        let out = Intl.NumberFormat('ru').format(money).toString()
        if (moneyData.length > 1) {
            if (moneyData[1].length < 2) {
                out += '0'
            }
        } else {
            out += ',00'
        }
        return out + " ₽"
    }
    const urlParams = new URLSearchParams(window.location.search)

    const text_payFull = 'Оплата 100%'
    const text_pay = 'Оплата 30%'
    const linkConditions = "https://www.zapolarye.ru/about/accommodation/"
    const linkPersonal = "https://www.zapolarye.ru/polozhenie-o-konfidentsialnosti/"
    const linkRefund = "https://zapolarye.ru/about/official-documents/zayavlenie-na-vozvrat/"
    const linkOfferAgree = "https://www.zapolarye.ru/upload/medialibrary/105/0i2wbco72wy9svrrhhppoio4n4jd6oup.docx"
    return <>
        <p className='mt-12'>Стоимость</p>
        <p className={styles.price}>{moneyToText(totalSum)}</p>
        <p className='mt-12'>Оплачено</p> 
        <p className={styles.price}>{moneyToText(totalSum - balanceAmount)}</p>
        <p className='mt-12'>Осталось оплатить</p>
        <p className={styles.price}>{moneyToText(balanceAmount)}</p>
        {urls && urls.linkFull && balanceAmount > 0 && <Button title={text_payFull} disabled={!checked1 || !checked2 || !isAllValidate} onClick={async () => {
            SoapRequest(urlParams.get('uuid'), urlParams.get('hotel'), urlParams.get('lang'), 'writeEmail', {email: email,}, 'file_test.php');
            location.href=urls.linkFull
            }}><img className={styles.icon} src={payicons}/></Button>}
        {urls && urls.link && totalSum - balanceAmount <= 0 && <Button title={text_pay} disabled={!checked1 || !checked2 || !isAllValidate} onClick={() => {
            SoapRequest(urlParams.get('uuid'), urlParams.get('hotel'), urlParams.get('lang'), 'writeEmail', {email: email,}, 'file_test.php');
            location.href=urls.link
            }}><img className={styles.icon} src={payicons}/></Button>}
        {!isAllValidate && <p className={styles.error}>* Информация о гостях заполнена не до конца - необходимо заполнить ФИО каждого гостя.</p>}
        {urls && (urls.linkFull || urls.link) && <div className={styles.accept}>
                <Switch checked={checked1} setChecked={setChecked1}/>
                <p>Я согласен с <a href={linkConditions}>условиями бронирования</a> и <a href={linkOfferAgree}>договором оферты</a></p>
            </div>}
        {urls && (urls.linkFull || urls.link) && <div className={styles.accept}>
            <Switch checked={checked2} setChecked={setChecked2}/>
            <p>Я согласен с <a href={linkPersonal}>обработкой персональных данных и положением о конфиденциальности</a></p>
        </div>}
        <div className='mt-12'>
        <Input
            placeholder="Электронная почта"
            value={email}
            onChange={setEmail}
        />
        <p className={styles.impo} >Для отправки чека нобходимо заполнить поле «Электронная почта»</p>
        </div>
        <Button title={"Отмена бронирования"} onClick={() => {setConfirmIsVisible(true)}}/>
        <Modal isVisible={confirmIsVisible} onClose={() => setConfirmIsVisible(false)} onBackdropClick={() => setConfirmIsVisible(false)} title={"Вы уверены, что хотите отменить бронь?"}>
            <p className={styles.confirmImportant}>Возврат денежных средств возможен только при заполнении заявки на возврат. </p>
            <a href={linkRefund}>Инструкция по осуществлению возврата</a>
            <div className={styles.accept}>
                <Switch checked={finalChecked} setChecked={setFinalChecked}/>
                <p>Я ознакомился с инструкцией по осуществлению возврата</p>
            </div>
            <Button title="Отмена бронирования" disabled={!finalChecked} onClick={() => {
                SoapRequest(urlParams.get('uuid'), urlParams.get('hotel'), urlParams.get('lang'), 'cancelPay', {guestGroup: guestGroup,}, 'file_test.php');
                location.href={linkRefund};
            }}/>
        </Modal>
    </>
    
};

export default PaymentContainer;





