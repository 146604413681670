import React from 'react';
import Button from '../Button';
import Switch from '../Switch';
import Modal from '../Modal';
import Input from '../Input';
import styles from './index.module.css';
import payicons from '../../icons/pay.png'
import SoapRequest from '../../service';
import { useState } from 'react';

const PaymentContainerAddService = ({paymentData, urls, hotel}) => {
    if (!paymentData) return
    const {
        totalSum,
        balanceAmount,
        vatRate,
        vatAmount,
        netAmount,
        grossAmount,
    } = paymentData;
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [email, setEmail] = useState('')

    
    const moneyToText = (money) => {
        const moneyData = money.toString().split('.')
        let out = Intl.NumberFormat('ru').format(money).toString()
        if (moneyData.length > 1) {
            if (moneyData[1].length < 2) {
                out += '0'
            }
        } else {
            out += ',00'
        }
        return out + " ₽"
    }
    const urlParams = new URLSearchParams(window.location.search)
    



    let linkConditions = "https://www.ostrova-hotel.ru/download/pravila-prebyvaniya-v-spa-otele-ostrova.pdf"
    let linkPersonal = "https://www.ostrova-hotel.ru/polozhenie"

    let text_payFull = 'Оплата'
    return <>
        <p className='mt-12'>Стоимость</p>
        <p className={styles.price}>{moneyToText(totalSum)}</p>
        <p className='mt-12'>Оплачено</p> 
        <p className={styles.price}>{moneyToText(totalSum - balanceAmount)}</p>
        <p className='mt-12'>Осталось оплатить</p>
        <p className={styles.price}>{moneyToText(balanceAmount)}</p>
        {urls && urls.linkFull && balanceAmount > 0 && <Button title={text_payFull} disabled={!checked1 || !checked2} onClick={() => {
            SoapRequest(urlParams.get('uuid'), urlParams.get('hotel'), urlParams.get('lang'), 'writeEmail', {email: email,}, 'addService_test.php');
            location.href=urls.linkFull}}><img className={styles.icon} src={payicons}/></Button>}
        {urls && (urls.linkFull || urls.link) && <div className={styles.accept}>
                <Switch checked={checked1} setChecked={setChecked1}/>
                <p>Я согласен с <a href={linkConditions}>порядком пребывания</a></p>
            </div>}
        {urls && (urls.linkFull || urls.link) && <div className={styles.accept}>
            <Switch checked={checked2} setChecked={setChecked2}/>
            <p>Я согласен с <a href={linkPersonal}>обработкой персональных данных и положением о конфиденциальности</a></p>
        </div>}
        <div className='mt-12'>
        <Input
            placeholder="Электронная почта"
            value={email}
            onChange={setEmail}
        />
        
        </div>
    </>



};

export default PaymentContainerAddService;





