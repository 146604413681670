import React from 'react';
import check from '../../icons/check.svg';
import useClassName from '../../hooks/useClassName';
import styles from './index.module.css';

const Switch = ({style, checked, setChecked}) => {
    const onClickHandler = () => {
        setChecked(!checked);
    };
    const className = `
        ${styles.Switch}
        ${useClassName(checked, styles.Switch__active)}
    `;
    return <div className={className} onClick={onClickHandler} style={style}>
        <img src={check}/>
    </div>;
};

export default Switch;
