import styles from './index.module.css';
import Area from '../../components/Area'
import InfoComp from '../../components/InfoComp';
import useTitle from '../../hooks/useTitle';
import SoapRequest from '../../service';
import { useEffect, useState } from 'react';
import Requisites from '../../components/Requisites';
import PaymentContainerAddService from '../../components/PaymentContainerAddService';
import ServiceTable from '../../components/ServiceTable';

function prepareData(rawData) {
  return {
    itemPrice: moneyToText(rawData['InvoiceItemPrice'].toString()),
    itemQuantity: rawData['InvoiceItemQuantity'].toString(),
    itemDescription: rawData['InvoiceItemDescription'].toString(),
    itemSum: moneyToText(rawData['InvoiceItemSum'].toString())
  }
}

const moneyToText = (money) => {
  const moneyData = money.toString().split('.')
  let out = Intl.NumberFormat('ru').format(money).toString()
  return out + " ₽"
}


function TestAdditionalServices() {
  const [data, setData] = useState(null);
  const [urls, setUrls] = useState(null);
  const urlParams = new URLSearchParams(window.location.search)

  const convertDate = (date) => {
    let textDate = new Date(date).toLocaleDateString()
    if (textDate === 'Invalid Date' || textDate === '01.01.1') {
      textDate = ''
    }
    return textDate
  }

  const getData = async () => {
    if (data) return
    const rawData = await SoapRequest(urlParams.get('uuid'), urlParams.get('hotel'), urlParams.get('lang'), 'getData', '', 'addService_test.php')
    const rawUsersData = rawData['soap:Envelope']['soap:Body']['m:GetGroupReservationDetailsResponse']['m:return']['Invoice']['InvoiceItems']
    const rawAdditionalData = rawData['soap:Envelope']['soap:Body']['m:GetGroupReservationDetailsResponse']['m:return']['Invoice']
    const servicesData = []
    if (rawUsersData.length) {
      for (let i = 0; i < rawUsersData.length; i++) {
        servicesData.push(prepareData(rawUsersData[i]))
      }
    } else {
      servicesData.push(prepareData(rawUsersData))
    }
    
    const additionalData = {
      totalSum: rawAdditionalData['InvoiceSum'].toString(),
      balanceAmount: rawAdditionalData['InvoiceBalance'].toString(),
      vatRate: rawAdditionalData['VatRates']['VATRate'].toString(),
      vatAmount: moneyToText(rawAdditionalData['VatRates']['VATAmount'].toString()),
      netAmount: moneyToText(rawAdditionalData['VatRates']['NETAmount'].toString()),
      grossAmount: moneyToText(rawAdditionalData['VatRates']['GrossAmount'].toString()),
    }
    
    setData([servicesData, additionalData])
    getURLs();
  }

  const getURLs = async () => {
    if (urls) return;

    const rawData = await SoapRequest(urlParams.get('uuid'), urlParams.get('hotel'), urlParams.get('lang'), 'getURLs', '', 'addService_test.php');
    if (!rawData.data) {
      setUrls({link:null, linkFull:null})
      if (rawData.refresh) {
        location.reload()
      }
      return;
    }
    setUrls({link:rawData.data.Link, linkFull:rawData.data.LinkFull})
    if (rawData.refresh) {
      location.reload()
    }
  }

  useTitle('Оплата доп. услуг');
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className={styles.mainContainer}>
        <div className={styles.dataContainer}>
          <h2 className={styles.generalTitle}>Оплата дополнительных услуг</h2>
          <Area title="Контактные данные отеля">
            <Requisites title={"Санаторий «Заполярье», г. Сочи"}>        
              <p>Адрес места нахождения:</p>
              <p>Российская Федерация, Краснодарский край, г.Сочи, ул.Пирогова, д.10</p>
              <p>Адрес для корреспонденции:</p>
              <p>354008, Российская Федерация, Краснодарский край, г. Сочи, ул. Пирогова, д.10</p>
              <p>ИНН / КПП: 2320126214 / 232001001</p>
              <p>Р/с 40702810251290000061</p>
              <p>ЮЖНЫЙ ФИЛИАЛ ПАО РОСБАНК</p>
              <p>г. Ростов-на-Дону</p>
              <p>К/с 30101810400000000239</p>
              <p>БИК 046015239</p>
              <p>Телефон: 8 (862) 259-93-12</p>
              <p>Факс: 8 (862) 259-93-13</p>
              <p>Эл. почта: info@zapolarye.ru</p>
            </Requisites>
            <InfoComp iconName="geo" >РОССИЯ, 354008, Краснодарский край,г. Сочи, ул. Пирогова, 10</InfoComp>
            <InfoComp iconName="geo">sales@zapolarye.ru</InfoComp>
            <InfoComp iconName="phone">+7 (862) 259-1-777, 259-92-17, 259-99-52</InfoComp>
            <InfoComp iconName="geo"><a href="https://www.zapolarye.ru">www.zapolarye.ru</a></InfoComp>
            
          </Area>
          <Area title="Услуги">
            <ServiceTable data={data}/>

          </Area>
        </div>
          <div className={styles.paymentContainer}>
            <Area title="Оплата" style={{'marginTop':'16px'}}>
              <PaymentContainerAddService paymentData={data ? data[1] : {
                totalSum: '0',
                balanceAmount: '0',
                reservationConditions: '',
                firstDaySum: '0',}} urls={urls} hotel={urlParams.get('hotel')}/>
            </Area>
          </div>
        </div>
    </div>
  );
}

export default TestAdditionalServices;
