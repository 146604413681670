import React, { useEffect, useState, useRef } from 'react';

import styles from './index.module.css';
import useClassName from '../../hooks/useClassName';


const TextArea = ({
    value,
    onChange,
    placeholder = '',
    type = 'text',
    style,
    maxLength,
    alignCenter = false,
    readOnly = false,
    isRequired = false,
}) => {
    const [height, setHeight] = useState(48)
    const ref = useRef(null)

    const isValid = value.length !== 0;

    useEffect(() => {
        setHeight(ref.current.scrollHeight)
    },[])

    const className = `
        ${styles.TextArea}
        ${useClassName(isValid, styles.TextAreaValid)}
        ${useClassName(readOnly, styles.TextArea__readOnly)}
        ${useClassName(isRequired, styles.TextAreaIsRequired)}
    `;

    const onInputCallback = (e) => {
        onChange(e.target.value);
        setHeight(Math.floor(e.target.scrollHeight/24)*24);
    };

    return (
        <div className={className} style={style}>
            <textarea
                type={type}
                value={value}
                onInput={onInputCallback}
                maxLength={maxLength}
                placeholder={alignCenter ? placeholder : undefined}
                style={{textAlign: alignCenter ? 'center' : 'left', height}}
                readOnly={readOnly}
                wrap="hard"
                ref={ref}
            />
            {!alignCenter && <span>{placeholder}</span>}
        </div>
    );
};

export default TextArea;
