import './App.css';
import Accommodation_san from './public/sanzap/pages/Accommodation'
import AdditionalServices_san from './public/sanzap/pages/AdditionalServices'
import TestAccommodation_san from './test/sanzap/pages/TestAccommodation'
import TestAdditionalServices_san from './test/sanzap/pages/TestAdditionalServices'

import Accommodation_spa from './public/spa/pages/Accommodation'
import AdditionalServices_spa from './public/spa/pages/AdditionalServices'
import TestAccommodation_spa from './test/spa/pages/TestAccommodation'
import TestAdditionalServices_spa from './test/spa/pages/TestAdditionalServices'
import Unsubscribe from './public/sanzap/pages/Unsubscribe';


function App() {
  const urlParams = new URLSearchParams(window.location.search);
  
  if (urlParams.get('hotel') == '1445') {
    if (urlParams.get('test')) {
      if (urlParams.get('srv')) {
        return <TestAdditionalServices_san/>
      }
      return <TestAccommodation_san/>
    } else {
      if (urlParams.get('srv')) {
        return <AdditionalServices_san/>
      }
      if (urlParams.get('unsub')) {
        return <Unsubscribe/>
      }
      return <Accommodation_san/>
    }
  } else if (urlParams.get('hotel') == '1446') {
    if (urlParams.get('test')) {
      if (urlParams.get('srv')) {
        return <TestAdditionalServices_spa/>
      }
      return <TestAccommodation_spa/>
    } else {
      if (urlParams.get('srv')) {
        return <AdditionalServices_spa/>
      }
      return <Accommodation_spa/>
    }
  }
  

}

export default App;
