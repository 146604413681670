import styles from './index.module.css';
import Area from '../../components/Area'
import InfoComp from '../../components/InfoComp';
import useTitle from '../../hooks/useTitle';
import GuestDataForm from '../../components/GuestDataForm';
import SoapRequest from '../../service';
import { useEffect, useState } from 'react';
import PaymentContainer from '../../components/PaymentContainer';
import Requisites from '../../components/Requisites';


function Accommodation() {
  const [data, setData] = useState(null);
  const [urls, setUrls] = useState(null);
  const [tourUrl, setTourUrl] = useState('');
  const [allValidates, setValidates] = useState([]);
  const urlParams = new URLSearchParams(window.location.search)
  const setValidateHandle = (id, isValid) => {
    setValidates((v) => [...v].map((val, index) => index == id ? isValid : val))
  }

  const convertDate = (date) => {
    let textDate = new Date(date).toLocaleDateString()
    if (textDate === 'Invalid Date' || textDate === '01.01.1') {
      textDate = ''
    }
    return textDate
  }

  const getData = async () => {
    if (data) return
    const rawData = await SoapRequest(urlParams.get('uuid'), urlParams.get('hotel'), urlParams.get('lang'), 'getData', '', 'file.php')
    const rawUsersData = rawData['soap:Envelope']['soap:Body']['m:GetGroupReservationDetailsResponse']['m:return']['ExternalReservationStatusRow']
    const rawAdditionalData = rawData['soap:Envelope']['soap:Body']['m:GetGroupReservationDetailsResponse']['m:return']
    const usersData = []
    if (rawUsersData.length) {
      for (let i = 0; i < rawUsersData.length; i++) {
        usersData.push({
          firstName: rawUsersData[i]['GuestFirstName'].toString(),
          lastName: rawUsersData[i]['GuestLastName'].toString(),
          secondName: rawUsersData[i]['GuestSecondName'].toString(),
          birthDate: convertDate(rawUsersData[i]['GuestBirthDate']),
          country: rawUsersData[i]['GuestCitizenship'].toString() !== '' ? rawUsersData[i]['GuestCitizenship'].toString() : "RU",
          documentType: rawUsersData[i]['GuestIdentityDocumentType'].toString() !== '' ? rawUsersData[i]['GuestIdentityDocumentType'].toString() : "21",
          documentSeries: rawUsersData[i]['GuestIdentityDocumentSeries'].toString(),
          documentNumber: rawUsersData[i]['GuestIdentityDocumentNumber'.toString()],
          issueDate: convertDate(rawUsersData[i]['GuestIdentityDocumentIssueDate']),
          validUntil: convertDate(rawUsersData[i]['GuestIdentityDocumentValidToDate']),
          divisionCode: rawUsersData[i]['GuestIdentityDocumentUnitCode'].toString(),
          issuedBy: rawUsersData[i]['GuestIdentityDocumentIssuedBy'].toString(),
          adress: rawUsersData[i]['GuestAddress'].toString(),
          registrationDate: convertDate(rawUsersData[i]['AddressRegistrationDate']),
          photo1: null,
          photo1_filename: null,
          registrationPhoto: null,
          registrationPhoto_filename: null,
          evidencePhoto: null,
          evidencePhoto_filename: null,
          roomTypeDescription: rawUsersData[i]['RoomTypeDescription'].toString(),
          accommodationType: rawUsersData[i]['AccommodationType'].toString(),
          accommodationTypeDescription: rawUsersData[i]['AccommodationTypeDescription'].toString(),
          checkInDate: convertDate(rawUsersData[i]['CheckInDate']),
          checkOutDate: convertDate(rawUsersData[i]['CheckOutDate']),
          roomRateDescription: rawUsersData[i]['RoomRateDescription'].toString(),
          uuid: rawUsersData[i]['UUID'],
          guestCode: rawUsersData[i]['GuestCode'],
          guestGroup: rawAdditionalData['GuestGroup'],
          reservationCode: rawUsersData[i]['ExtReservationCode']
        })
      }
    } else {
      usersData.push({
        firstName: rawUsersData['GuestFirstName'].toString(),
        lastName: rawUsersData['GuestLastName'].toString(),
        secondName: rawUsersData['GuestSecondName'].toString(),
        birthDate: convertDate(rawUsersData['GuestBirthDate']),
        country: rawUsersData['GuestCitizenship'].toString() !== '' ? rawUsersData['GuestCitizenship'].toString() : "RU",
        documentType: rawUsersData['GuestIdentityDocumentType'].toString() !== '' ? rawUsersData['GuestIdentityDocumentType'].toString() : "21",
        documentSeries: rawUsersData['GuestIdentityDocumentSeries'].toString(),
        documentNumber: rawUsersData['GuestIdentityDocumentNumber'.toString()],
        issueDate: convertDate(rawUsersData['GuestIdentityDocumentIssueDate']),
        validUntil: convertDate(rawUsersData['GuestIdentityDocumentValidToDate']),
        divisionCode: rawUsersData['GuestIdentityDocumentUnitCode'].toString(),
        issuedBy: rawUsersData['GuestIdentityDocumentIssuedBy'].toString(),
        adress: rawUsersData['GuestAddress'].toString(),
        registrationDate: convertDate(rawUsersData['AddressRegistrationDate']),
        photo1: null,
        photo1_filename: null,
        registrationPhoto: null,
        registrationPhoto_filename: null,
        evidencePhoto: null,
        evidencePhoto_filename: null,
        roomTypeDescription: rawUsersData['RoomTypeDescription'].toString(),
        accommodationType: rawUsersData['AccommodationType'].toString(),
        accommodationTypeDescription: rawUsersData['AccommodationTypeDescription'].toString(),
        checkInDate: convertDate(rawUsersData['CheckInDate']),
        checkOutDate: convertDate(rawUsersData['CheckOutDate']),
        roomRateDescription: rawUsersData['RoomRateDescription'].toString(),
        uuid: rawUsersData['UUID'],
        guestCode: rawUsersData['GuestCode'],
        guestGroup: rawAdditionalData['GuestGroup'],
        reservationCode: rawUsersData['ExtReservationCode']
      })
    }
    
    const additionalData = {
      totalSum: rawAdditionalData['TotalSum'].toString(),
      balanceAmount: rawAdditionalData['BalanceAmount'].toString(),
      reservationConditions: rawAdditionalData['ReservationConditions'],
      guestGroup: rawAdditionalData['GuestGroup'],
      firstDaySum: rawAdditionalData['FirstDaySum'],
    }
    const validatesBase = []
    for (let i = 0; i < usersData.length; i++) {
      validatesBase.push(false)
    }
    setValidates(validatesBase)
    const rawTourUrl = await SoapRequest(urlParams.get('uuid'), urlParams.get('hotel'), urlParams.get('lang'), 'getTourUrl', '', 'file.php')
    setTourUrl(rawTourUrl.data)

    setData([usersData, additionalData])
    getURLs();
  }

  const getURLs = async () => {
    if (urls) return;

    const rawData = await SoapRequest(urlParams.get('uuid'), urlParams.get('hotel'), urlParams.get('lang'), 'getURLs', '', 'file.php');
    if (!rawData.data) {
      setUrls({link:null, linkFull:null})
      return;
    }
    setUrls({link:rawData.data.Link, linkFull:rawData.data.LinkFull})
  }

  useTitle('Оплата бронирования');
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className={styles.mainContainer}>
        <div className={styles.dataContainer}>
          <h2 className={styles.generalTitle}>Подтверждение брони №{data ? data[1].guestGroup : '0'}</h2>
          <Area title="Контактные данные отеля">
            <Requisites title={"Санаторий «Заполярье», г. Сочи"}>        
              <p>Адрес места нахождения:</p>
              <p>Российская Федерация, Краснодарский край, г.Сочи, ул.Пирогова, д.10</p>
              <p>Адрес для корреспонденции:</p>
              <p>354008, Российская Федерация, Краснодарский край, г. Сочи, ул. Пирогова, д.10</p>
              <p>ИНН / КПП: 2320126214 / 232001001</p>
              <p>Р/с 40702810251290000061</p>
              <p>ЮЖНЫЙ ФИЛИАЛ ПАО РОСБАНК</p>
              <p>г. Ростов-на-Дону</p>
              <p>К/с 30101810400000000239</p>
              <p>БИК 046015239</p>
              <p>Телефон: 8 (862) 259-93-12</p>
              <p>Факс: 8 (862) 259-93-13</p>
              <p>Эл. почта: info@zapolarye.ru</p>
            </Requisites>
            <InfoComp iconName="geo" >РОССИЯ, 354008, Краснодарский край,г. Сочи, ул. Пирогова, 10</InfoComp>
            <InfoComp iconName="geo">sales@zapolarye.ru</InfoComp>
            <InfoComp iconName="phone">+7 (862) 259-1-777, 259-92-17, 259-99-52</InfoComp>
            <InfoComp iconName="geo"><a href="https://www.zapolarye.ru">www.zapolarye.ru</a></InfoComp>
            <p className={styles.miniWarn}>Для экспресс-заселения просьба заполнить паспортные данные каждого гостя.</p>   
          </Area>
          <Area title="Информация о гостях">
            {data && data[0].map((item, index) => <GuestDataForm title={"Гость " + (index + 1) + " (" + item.accommodationTypeDescription + ")"} guestData={item} key={item.uuid} setValidate={setValidateHandle} id={index}/>)}
          </Area>
          <Area title ="">
              <p>Для получения подробной информации о забронированной категории номера можно перейти по ссылке ниже.</p>
              <p><a href={tourUrl}>Ссылка на виртуальный тур</a></p>
              <p className='mt-12'></p>
          </Area>
          <Area title="Тариф">
            <p dangerouslySetInnerHTML={data ? {__html:data[1].reservationConditions} : {__html:''}}></p>
          </Area>
        </div>
          <div className={styles.paymentContainer}>
            <Area title="Оплата" style={{'marginTop':'16px'}}>
              <PaymentContainer paymentData={data ? data[1] : {
                totalSum: '0',
                balanceAmount: '0',
                reservationConditions: '',
                firstDaySum: '0',}} isAllValidate={!(allValidates.filter((val) => !val).length > 0)} urls={urls} hotel={urlParams.get('hotel')}/>
            </Area>
          </div>
        </div>
    </div>
  );
}

export default Accommodation;
