import React from 'react';
import styles from './index.module.css';

function Row({itemName, itemPrice, itemQuantity, itemSum}) {
    return <tr>
        <td>{itemName}</td>
        <td>{itemPrice}</td>
        <td>{itemQuantity}</td>
        <td>{itemSum}</td>
    </tr>
}

const MemoRow = React.memo(Row)

function ServiceTable(props) {
    if (!props.data)
        return null
    return <>
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>Название</th>
                    <th>Цена</th>
                    <th>Количество</th>
                    <th>Суммарная стоимость</th>
                </tr>
            </thead>
            <tbody>
                {props.data[0].map((data, index) => <MemoRow {...data} key={data.itemName + index}/>)}
            </tbody>
        </table>
        <div className={styles.total}>
            <p>Сумма без НДС: {props.data[1].netAmount}</p>
            <p>НДС ({props.data[1].vatRate}%): {props.data[1].vatAmount}</p>
            <p>Итого: {props.data[1].grossAmount}</p>
        </div>
    </>;
}

export default ServiceTable;
