import style from './index.module.css'
import geoIcon from '../../icons/mark.svg';
import phoneIcon from '../../icons/phone.svg';


const ICONS = {
    geo: geoIcon,
    phone: phoneIcon,
};

function InfoComp(props) {
    return <div className={style.general}>
        <img className={style.icon} src={ICONS[props.iconName]} />
        {props.children}
    </div>
}

export default InfoComp