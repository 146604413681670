
export default async function SoapRequest(uuid, hotel, lang, command, reqData, file) {

    function doPHPRequest(uuid, hotel, lang, reqData, file) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.open('POST', file, true);
        var requestBody = JSON.stringify({'uuid' : uuid, 'hotel': hotel, 'lang': lang, 'command' : command, 'reqData': reqData});

        const p = new Promise((resolve, reject) => {
            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState == 4) {
                    resolve(xmlhttp.responseText)
                }
            }
        });
    
        xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');
        xmlhttp.send(requestBody);
        return p;
    }
    
    function xmlToJson(xml) {
        const obj = {};
        const rootNode = xml;
        
        for (let i = 0; i < rootNode.children.length; i++) {
            const node = rootNode.children[i];
            // && node.attributes.length === 0
            if (node.children.length === 0) {
                obj[node.nodeName] = node.textContent;
            }
            else {
                const nodeName = node.nodeName;

                // if (node.attributes.length > 0) {
                //     obj[nodeName] = {};
            
                //     for (let j = 0; j < node.attributes.length; j++) {
                //         const attribute = node.attributes[j];
                //         obj[nodeName]['@' + attribute.nodeName] = attribute.nodeValue;
                //     }
                // }
                if (node.children.length > 0) {

                    const childObj = xmlToJson(node)
                    if (nodeName in obj) {
                        if (Array.isArray(obj[nodeName])) {
                            obj[nodeName].push(childObj)
                        } else {
                            obj[nodeName] = [obj[nodeName], childObj];
                        }
                    } else {
                        obj[nodeName] = childObj;
                    }
                    
                }
            }
        }
        return obj;
      }
    
    const xmlText = await doPHPRequest(uuid, hotel, lang, reqData, file)
    if (command === 'getURLs') {
        if (xmlText === '') 
            return {'link':null,'linkFull':null}
        let data = JSON.parse(xmlText);

        return data;
    }
    if (command === 'getData') {
        const parser = new DOMParser();
        const xml = parser.parseFromString(xmlText, "text/xml")
        const data = xmlToJson(xml)
        
        return data
    }
    const data = JSON.parse(xmlText);
    return data;
    
}



