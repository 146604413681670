import {useState, useRef} from 'react';

export default function useForm(defaultState) {
    const prevState = useRef(defaultState);

    const [formState, setFormState] = useState(prevState.current);

    const useFieldHandler = (fieldName) => {
        return (v) => {
            setFormState((prev) => ({...prev, [fieldName]: v}));
        };
    };

    const resetForm = () => setFormState(prevState.current);

    const updateForm = () => {
        prevState.current = {...formState};
    };

    return [formState, useFieldHandler, resetForm, updateForm];
}
