import React, {useEffect, useRef, useState} from 'react';

import styles from './index.module.css';
import CloseIcon from './../../icons/close.svg';

const Modal = ({
    isVisible,
    backdropOpacity = 0.3,
    title,
    onBackdropClick,
    onClose,
    children,
    animationType = 'default',
    style,
    wrapperStyle,
}) => {
    const modalRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [translation, setTranslation] = useState({x: 0, y: 0});

    const className = `
        ${styles.Modal}
        ${isVisible ? styles.Modal__opened : styles.Modal__closed}
    `;
    const wrapperClassName = styles[`Modal__${isVisible ? 'opening' : 'closing'}__${animationType}`];

    const updateTranslation = () => {
        if (!modalRef.current) return;
        const {x, y} = modalRef.current.getBoundingClientRect();
        setTranslation({x: x + translation.x, y: y + translation.y});
    };

    useEffect(() => {
        if (isVisible) {
            setIsOpen(true);
            return;
        }
        setTimeout(() => setIsOpen(false), 250);
    }, [isVisible]);

    useEffect(() => {
        updateTranslation();
    }, [isOpen]);

    if (!isOpen) return null;
    return <div
        ref={modalRef}
        className={className}
        style={{
            ...style,
            backgroundColor: `rgba(0, 0, 0, ${backdropOpacity})`,
            top: -translation.y,
            left: -translation.x,
        }}
        onClick={onBackdropClick}
    >
        <div style={{
            padding: 16,
            ...wrapperStyle,
        }} className={wrapperClassName} onClick={e => e.stopPropagation()}>
            {onClose && <img
                src={CloseIcon}
                onClick={onClose}
                className={styles.CloseIcon}
                draggable={false}
                alt="Закрыть"
            />}
            {title && <h2 className={styles.Title}>{title}</h2>}
            {children}
        </div>
    </div>;
};

export default Modal;
