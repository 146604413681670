import React, { useRef } from 'react';

import Button from '../Button';
import styles from './index.module.css';
import DeleteIcon from '../../icons/close.svg';

const UploadImageButton = ({
    filename,
    setFilename,
    onUpload,
}) => {
    const fileInputRef = useRef();

    const onClick = () => {
        fileInputRef.current.click();
    };

    const onChange = () => {
        const file = fileInputRef.current.files[0];
        const reader = new FileReader();
        reader.onload = () => onUpload(reader.result);
        reader.readAsDataURL(file);
        setFilename(file.name);
    };

    const onClose = () => {
        onUpload(null);
        setFilename(null);
    };

    return <div className={styles.general}>
        {filename ? <div>
            <span>{filename}</span>
            <img src={DeleteIcon} onClick={onClose} />
        </div> : <Button title="Загрузить" onClick={onClick} />}
        <input
            type="file"
            ref={fileInputRef}
            className={styles.fileInput}
            onChange={onChange}
        />
    </div>;
};

export default UploadImageButton;
