import styles from './index.module.css';
import Area from '../../components/Area'
import InfoComp from '../../components/InfoComp';
import useTitle from '../../hooks/useTitle';
import SoapRequest from '../../service';
import { useEffect, useState } from 'react';
import Requisites from '../../components/Requisites';
import PaymentContainerAddService from '../../components/PaymentContainerAddService';
import ServiceTable from '../../components/ServiceTable';


function Unsubscribe() {
  const [data, setData] = useState(null);
  const urlParams = new URLSearchParams(window.location.search)

  const unsub = async () => {
    const rawData = await SoapRequest(urlParams.get('uuid'), urlParams.get('hotel'), urlParams.get('lang'), 'unsubscribe', '', 'unsub.php');
    setData(rawData.data)
  }

  useTitle('Отказ от рассылки');
  useEffect(() => {
    unsub();
  }, []);

  return (
    <div>
      <div className={styles.mainContainer}>
        <div className={styles.dataContainer}>
          {data === true ? <>
            <h2 className={styles.generalTitle}>Вы отказались от получения почтовой рассылки</h2>
          </> : data === null ? <>
            <h2 className={styles.generalTitle}>Загрузка...</h2>
          </> : <>
            <h2 className={styles.generalTitle}>Возникла непредвиденная ошибка</h2>
          </>}
          
        </div>
      </div>
    </div>
  );
}

export default Unsubscribe;
