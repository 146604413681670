import React from 'react';

import useClassName from '../../hooks/useClassName';
import ActivityIndicator from '../ActivityIndicator';
import styles from './index.module.css';

const getClassNameBase = (size) => {
    switch (size) {
    case 'big':
        return 'Big';
    case 'small':
        return 'Small';
    default:
        return '';
    }
};

const getIndicatorSize = (size) => {
    switch (size) {
    case 'big':
        return 28;
    case 'small':
        return 8;
    default:
        return 18;
    }
};

const Button = ({
    title,
    onClick,
    style,
    disabled = false,
    isLoading = false,
    width,
    children,
    size = 'default',
    color,
}) => {
    const clickHandler = (e) => {
        if (disabled || isLoading) return;
        onClick(e);
    };

    const classNameBase = `${getClassNameBase(size)}Button`;

    const isAvailable = !(isLoading || disabled);
    const className = `
        ${styles[classNameBase]}
        ${useClassName(disabled, styles[`${classNameBase}__disabled`])}
        ${useClassName(isAvailable, styles[`${classNameBase}__available`])}
    `;

    return <button
        className={className}
        onClick={clickHandler}
        style={{...style, width, backgroundColor: color}}
    >
        {isLoading ? <ActivityIndicator
            size={getIndicatorSize(size)}
        /> : title}
        {children}
    </button>;
};

export default Button;
