import style from './area.module.css'

function Area(props) {
    return <div className={style.general} style={props.style}>
        <h2>{props.title}</h2>
        <div>
            {props.children}
        </div>
    </div>
}

export default Area