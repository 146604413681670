import React from 'react';

import styles from './index.module.css';
import useClassName from '../../hooks/useClassName';

const MASKS = {
    date: {maxSymbols:[2,2,4], separator:'.', function:(array) => {
        if (array.length > 0) array[0] = array[0] < 31 ? array[0] : '31'
        if (array.length > 1) array[1] = array[1] < 12 ? array[1] : '12'
        if (array.length > 2) {
            if (array[2].length > 3) {
                if (array[2] < 3000) {
                    array[2] = array[2] > 1900 ? array[2] : '1900'
                } else {
                    array[2] = '2999'
                }
            }
        }

    }},
    code: {maxSymbols:[3, 3], separator:'-', function:(array) => {return array}},
    passport_series: {maxSymbols:[4], separator:null, function:(array) => {return array}},
    passport_number: {maxSymbols:[6], separator:null, function:(array) => {return array}},
};



function getTextArray(mask, text) {
    const array = mask['separator'] ? text.split(mask['separator']) : [text]
    for (let i = 0; i < array.length; i++) {
        array[i] = array[i].split('').filter((char) => !isNaN(Number(char))).join('')
        
        if (array[i].length > mask['maxSymbols'][i]) {
            if (i < mask['maxSymbols'].length - 1) {
                text = array[i].substring(mask['maxSymbols'][i])
                array[i] = array[i].substring(0, mask['maxSymbols'][i])
                array[i+1] = text + array[i+1]
            }
            else {
                array[i] = array[i].substring(0, mask['maxSymbols'][i])
            }
        }
    }
    if (array.length < mask['maxSymbols'].length && array.at(-1).length == mask['maxSymbols'][array.length-1]) {
        array.push('')
    } else {
        while (array.at(-1) === '' && array.length > 1) {
            array.pop()
        }
    }


    
    
    
    mask['function'](array);
    return array.join(mask['separator']);
}

const setText = (mask, text) => {
    if (!mask) return text;
    const textMask = MASKS[mask];
    let textArray;
    textArray = getTextArray(textMask, text);
    return textArray
};

const Input = ({
    value,
    onChange,
    placeholder = '',
    type = 'text',
    style,
    maxLength,
    alignCenter = false,
    readOnly = false,
    mask = null,
    isRequired = false,
}) => {
    const isValid = value.length !== 0;
    const className = `
        ${styles.Input}
        ${useClassName(isValid, styles.InputValid)}
        ${useClassName(readOnly, styles.Input__readOnly)}
        ${useClassName(isRequired, styles.InputIsRequired)}
    `;

    const onInputCallback = (e) => {
        onChange(setText(mask, e.target.value));
    };

    return (
        <div className={className} style={style}>
            <input
                type={type}
                value={value}
                onInput={onInputCallback}
                maxLength={maxLength}
                placeholder={alignCenter ? placeholder : undefined}
                style={{textAlign: alignCenter ? 'center' : 'left'}}
                readOnly={readOnly}
            />
            {!alignCenter && <span>{placeholder}</span>}
        </div>
    );
};

export default Input;
