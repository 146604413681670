import React, {useCallback, useEffect, useState} from 'react';

import useClassName from '../../hooks/useClassName';
import styles from './index.module.css';

const Dropdown = ({
    data,
    renderItem,
    renderValue,
    label,
    value,
    setValue,
    style,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [className, setClassName] = useState('');

    const activeItem = data.find((item) => item.value === value);
    const isWrapperVisible = className === styles.Dropdown__wrapper__opened;
    const dropdownClassname = `
        ${styles.Dropdown}
        ${useClassName(isWrapperVisible, styles.Dropdown__opened)}
    `;
    const isLabelActive = isWrapperVisible || activeItem !== undefined;

    const labelClassname = `
        ${styles.Dropdown__label}
        ${useClassName(isLabelActive, styles.Dropdown__label__active)}
    `;

    const renderItemFunction = (item) => {
        if (!renderItem) return item.value;
        return renderItem(item);
    };
    const renderValueFunction = (item) => {
        if (!renderValue) return renderItemFunction(item);
        return renderValue(item);
    };

    const open = useCallback((e) => {
        if (isOpen) return;
        e.stopPropagation();
        setIsOpen(true);
        setTimeout(() => setClassName(styles.Dropdown__wrapper__opened), 0);
    }, [isOpen]);
    const close = useCallback(() => {
        setClassName(styles.Dropdown__wrapper__closed);
        setTimeout(() => setIsOpen(false), 200);
    }, []);

    useEffect(() => {
        window.addEventListener('click', close);
        return () => window.removeEventListener('click', close);
    }, []);

    return <div
        className={dropdownClassname}
        style={style} onClick={open}
    >
        <p className={labelClassname}>{label}</p>
        <span>{activeItem && renderValueFunction(activeItem)}</span>
        {isOpen && <div className={`${styles.Dropdown__wrapper} ${className}`}>
            {data.map((item) => <span
                key={item.value}
                onClick={() => setValue(item.value)}
            >{renderItemFunction(item)}</span>)}
        </div>}
    </div>;
};

export default Dropdown;
