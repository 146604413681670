import React, { useState, useEffect } from 'react';

import styles from './index.module.css';
import useForm from '../../hooks/useForm';
import Input from '../Input';
import TextArea from '../TextArea';
import Dropdown from '../Dropdown';
import UploadImageButton from '../UploadImageButton';
import SoapRequest from '../../service';
import { useRef } from 'react';

const urlParams = new URLSearchParams(window.location.search)
const COUNTRIES = [
    {value:'AB', title:'Абхазия'},
    {value:'AU', title:'Австралия'},
    {value:'AT', title:'Австрия'},
    {value:'AZ', title:'Азербайджан'},
    {value:'AL', title:'Албания'},
    {value:'DZ', title:'Алжир'},
    {value:'AI', title:'Ангилья о. (GB)'},
    {value:'AO', title:'Ангола'},
    {value:'AD', title:'Андорра'},
    {value:'AQ', title:'Антарктика'},
    {value:'AG', title:'Антигуа и Барбуда'},
    {value:'AN', title:'Антильские о‐ва (NL)'},
    {value:'AR', title:'Аргентина'},
    {value:'AM', title:'Армения'},
    {value:'AW', title:'Аруба'},
    {value:'AF', title:'Афганистан'},
    {value:'BS', title:'Багамы'},
    {value:'BD', title:'Бангладеш'},
    {value:'BB', title:'Барбадос'},
    {value:'BH', title:'Бахрейн'},
    {value:'BY', title:'Беларусь'},
    {value:'BZ', title:'Белиз'},
    {value:'BE', title:'Бельгия'},
    {value:'BJ', title:'Бенин'},
    {value:'BM', title:'Бермуды'},
    {value:'BV', title:'Бове о. (NO)'},
    {value:'BG', title:'Болгария'},
    {value:'BO', title:'Боливия'},
    {value:'BA', title:'Босния и Герцеговина'},
    {value:'BW', title:'Ботсвана'},
    {value:'BR', title:'Бразилия'},
    {value:'BN', title:'Бруней Дарассалам'},
    {value:'BF', title:'Буркина-Фасо'},
    {value:'BI', title:'Бурунди'},
    {value:'BT', title:'Бутан'},
    {value:'VU', title:'Вануату'},
    {value:'VA', title:'Ватикан'},
    {value:'GB', title:'Великобритания'},
    {value:'HU', title:'Венгрия'},
    {value:'VE', title:'Венесуэла'},
    {value:'VG', title:'Виргинские о-ва (GB)'},
    {value:'VI', title:'Виргинские о-ва (US)'},
    {value:'AS', title:'Восточное Самоа (US)'},
    {value:'TP', title:'Восточный Тимор'},
    {value:'VN', title:'Вьетнам'},
    {value:'GA', title:'Габон'},
    {value:'HT', title:'Гаити'},
    {value:'GY', title:'Гайана'},
    {value:'GM', title:'Гамбия'},
    {value:'GH', title:'Гана'},
    {value:'GP', title:'Гваделупа'},
    {value:'GT', title:'Гватемала'},
    {value:'GN', title:'Гвинея'},
    {value:'GW', title:'issau - Гвинея-Бисау'},
    {value:'DE', title:'Германия'},
    {value:'GI', title:'Гибралтар'},
    {value:'HN', title:'Гондурас'},
    {value:'HK', title:'Гонконг (CN)'},
    {value:'GD', title:'Гренада'},
    {value:'GL', title:'Гренландия (DK)'},
    {value:'GR', title:'Греция'},
    {value:'GE', title:'Грузия'},
    {value:'GU', title:'Гуам'},
    {value:'DK', title:'Дания'},
    {value:'CD', title:'Демократическая Республика Конго'},
    {value:'DJ', title:'Джибути'},
    {value:'DM', title:'Доминика'},
    {value:'DO', title:'Доминиканская Республика'},
    {value:'EG', title:'Египет'},
    {value:'ZM', title:'Замбия'},
    {value:'EH', title:'Западная Сахара'},
    {value:'ZW', title:'Зимбабве'},
    {value:'IL', title:'Израиль'},
    {value:'IN', title:'Индия'},
    {value:'ID', title:'Индонезия'},
    {value:'JO', title:'Иордания'},
    {value:'IQ', title:'Ирак'},
    {value:'IR', title:'Иран'},
    {value:'IE', title:'Ирландия'},
    {value:'IS', title:'Исландия'},
    {value:'ES', title:'Испания'},
    {value:'IT', title:'Италия'},
    {value:'YE', title:'Йемен'},
    {value:'CV', title:'Кабо-Верде'},
    {value:'KZ', title:'Казахстан'},
    {value:'KY', title:'Каймановы о-ва (GB)'},
    {value:'KH', title:'Камбоджа'},
    {value:'CM', title:'Камерун'},
    {value:'CA', title:'Канада'},
    {value:'QA', title:'Катар'},
    {value:'KE', title:'Кения'},
    {value:'CY', title:'Кипр'},
    {value:'KG', title:'Киргизстан'},
    {value:'KI', title:'Кирибати'},
    {value:'CN', title:'Китай'},
    {value:'CC', title:'Кокосовые (Киилинг) о-ва (AU)'},
    {value:'CO', title:'Колумбия'},
    {value:'KM', title:'Коморские о-ва'},
    {value:'CG', title:'Конго'},
    {value:'CR', title:'Коста-Рика'},
    {value:'CI', title:'Кот д Ивуар'},
    {value:'CU', title:'Куба'},
    {value:'KW', title:'Кувейт'},
    {value:'CK', title:'Кука о-ва (NZ)'},
    {value:'LA', title:'Лаос'},
    {value:'LV', title:'Латвия'},
    {value:'LS', title:'Лесото'},
    {value:'LR', title:'Либерия'},
    {value:'LB', title:'Ливан'},
    {value:'LY', title:'Ливия'},
    {value:'LT', title:'Литва'},
    {value:'LI', title:'Лихтенштейн'},
    {value:'LU', title:'Люксембург'},
    {value:'MU', title:'Маврикий'},
    {value:'MR', title:'Мавритания'},
    {value:'MG', title:'Мадагаскар'},
    {value:'YT', title:'Майотта о. (KM)'},
    {value:'MO', title:'Макао (PT)'},
    {value:'MK', title:'Македония'},
    {value:'MW', title:'Малави'},
    {value:'MY', title:'Малайзия'},
    {value:'ML', title:'Мали'},
    {value:'MV', title:'Мальдивы'},
    {value:'MT', title:'Мальта'},
    {value:'MA', title:'Марокко'},
    {value:'MQ', title:'Мартиника'},
    {value:'MH', title:'Маршалловы о-ва'},
    {value:'MX', title:'Мексика'},
    {value:'FM', title:'Микронезия (US)'},
    {value:'MZ', title:'Мозамбик'},
    {value:'MD', title:'Молдова'},
    {value:'MC', title:'Монако'},
    {value:'MN', title:'Монголия'},
    {value:'MS', title:'Монсеррат о. (GB)'},
    {value:'MM', title:'Мьянма'},
    {value:'NA', title:'Намибия'},
    {value:'NR', title:'Науру'},
    {value:'NP', title:'Непал'},
    {value:'NE', title:'Нигер'},
    {value:'NG', title:'Нигерия'},
    {value:'NL', title:'Нидерланды'},
    {value:'NI', title:'Никарагуа'},
    {value:'NU', title:'Ниуэ о. (NZ)'},
    {value:'NZ', title:'Новая Зеландия'},
    {value:'NC', title:'Новая Каледония о. (FR)'},
    {value:'NO', title:'Норвегия'},
    {value:'NF', title:'Норфолк о. (AU)'},
    {value:'AE', title:'Объединенные Арабские Эмираты'},
    {value:'OM', title:'Оман'},
    {value:'PK', title:'Пакистан'},
    {value:'PW', title:'Палау (US)'},
    {value:'PS', title:'Палестинская автономия'},
    {value:'PA', title:'Панама'},
    {value:'PG', title:'Папуа-Новая Гвинея'},
    {value:'PY', title:'Парагвай'},
    {value:'PE', title:'Перу'},
    {value:'PN', title:'Питкэрн о-ва (GB)'},
    {value:'PL', title:'Польша'},
    {value:'PT', title:'Португалия'},
    {value:'PR', title:'Пуэрто-Рико (US)'},
    {value:'RE', title:'Реюньон о. (FR)'},
    {value:'CX', title:'Рождества о. (AU)'},
    {value:'RU', title:'Россия'},
    {value:'RW', title:'Руанда'},
    {value:'RO', title:'Румыния'},
    {value:'SV', title:'Сальвадор'},
    {value:'WS', title:'Самоа'},
    {value:'SM', title:'Сан Марино'},
    {value:'ST', title:'Сан-Томе и Принсипи'},
    {value:'SA', title:'Саудовская Аравия'},
    {value:'SZ', title:'Свазиленд'},
    {value:'SJ', title:'Свалбард и Ян Мейен о-ва (NO)'},
    {value:'SH', title:'Святой Елены о. (GB)'},
    {value:'KP', title:'Северная Корея (КНДР)'},
    {value:'MP', title:'Северные Марианские'},
    {value:'SC', title:'Сейшелы'},
    {value:'VC', title:'Сен-Винсент и Гренадины'},
    {value:'PM', title:'Сен-Пьер и Микелон (FR)'},
    {value:'SN', title:'Сенегал'},
    {value:'KN', title:'Сент-Кристофер и Невис'},
    {value:'LC', title:'Сент-Люсия'},
    {value:'SG', title:'Сингапур'},
    {value:'SY', title:'Сирия'},
    {value:'SK', title:'Словакия'},
    {value:'SI', title:'Словения'},
    {value:'US', title:'Соединенные Штаты Америки'},
    {value:'SB', title:'Соломоновы о-ва'},
    {value:'SO', title:'Сомали'},
    {value:'SD', title:'Судан'},
    {value:'SR', title:'Суринам'},
    {value:'SL', title:'Сьерра-Леоне'},
    {value:'TJ', title:'Таджикистан'},
    {value:'TH', title:'Таиланд'},
    {value:'TW', title:'Тайвань'},
    {value:'TZ', title:'Танзания'},
    {value:'TC', title:'Теркс и Кайкос о-ва (GB)'},
    {value:'TG', title:'Того'},
    {value:'TK', title:'Токелау о-ва (NZ)'},
];

const TYPES = [
    { value: '21', title: 'Паспорт' },
    { value: '03', title: 'Свидетельство о рождении' },
    { value: '115', title: 'Свидетельство о рождении, выданное за пределами РФ' },
    { value: 'ИП', title: 'Иностранный паспорт' },
    { value: 'default', title: 'Документ, удостоверяющий личность'}
];


const GuestDataForm = ({
    title,
    guestData,
    setValidate,
    id,
}) => {
    const {
        firstName,
        lastName,
        secondName,
        birthDate,
        country,
        documentType,
        documentSeries,
        documentNumber,
        issueDate,
        validUntil,
        divisionCode,
        issuedBy,
        adress,
        registrationDate,
        photo1,
        photo1_filename,
        registrationPhoto,
        registrationPhoto_filename,
        evidencePhoto,
        evidencePhoto_filename,
        roomTypeDescription,
        accommodationType,
        accommodationTypeDescription,
        checkInDate,
        checkOutDate,
        roomRateDescription,
        uuid,
        guestCode,
        guestGroup,
        reservationCode,
    } = guestData;

    const [isEditing, setIsEditing] = useState(false);
    const [showForm, setShowFormHandler] = useState('default');
    function setShowForm(documentType) {
        if (documentType == 'default') {
            return
        }
        if (!TYPES.find((el) => el.value === documentType)) {
            setShowFormHandler('default')
        } else {
            setShowFormHandler(documentType)
        }
    }


    const [form, useFieldHandler, resetForm, updateForm] = useForm({
        firstName,
        lastName,
        secondName,
        birthDate,
        country,
        documentType,
        documentSeries,
        documentNumber,
        issueDate,
        validUntil,
        divisionCode,
        issuedBy,
        adress,
        registrationDate,
        photo1,
        photo1_filename,
        registrationPhoto,
        registrationPhoto_filename,
        evidencePhoto,
        evidencePhoto_filename,
    });
    
    const firstNameHandler = useFieldHandler('firstName');
    const lastNameHandler = useFieldHandler('lastName');
    const secondNameHandler = useFieldHandler('secondName');
    const birthDateHandler = useFieldHandler('birthDate');
    const countryHandler = useFieldHandler('country');
    const documentTypeHandler = useFieldHandler('documentType');
    const documentSeriesHandler = useFieldHandler('documentSeries');
    const documentNumberHandler = useFieldHandler('documentNumber');
    const issueDateHandler = useFieldHandler('issueDate');
    const validUntilHandler = useFieldHandler('validUntil');
    const divisionCodeHandler = useFieldHandler('divisionCode');
    const issuedByHandler = useFieldHandler('issuedBy');
    const adressHandler = useFieldHandler('adress');
    const registrationDateHandler = useFieldHandler('registrationDate');
    const photo1Handler = useFieldHandler('photo1');
    const photo1_filenameHandler = useFieldHandler('photo1_filename');
    const registrationPhotoHandler = useFieldHandler('registrationPhoto');
    const registrationPhoto_filenameHandler = useFieldHandler('registrationPhoto_filename');
    const evidencePhotoHandler = useFieldHandler('evidencePhoto');
    const evidencePhoto_filenameHandler = useFieldHandler('evidencePhoto_filename');

    const [errorsMsgs, setErrorsMsgs] = useState([])

    useEffect(() => {
        setShowForm(form.documentType)
        let errors = checkRequired()
        errors = []
        if (errors.length > 0) {
            setValidate(id, false)
        } else {
            setValidate(id, true)
        }
    }, []);
    useEffect(() => {
        setShowForm(form.documentType)
    }, [form.documentType]);
    const errorsMsgsRef = useRef(null)

    const rules = {
        'not-empty': (v) => v.length !== 0,
        'digits-4': (v) => v.length >= 4,
        'digits-6': (v) => v.length >= 6,
        'digits-7': (v) => v.length >= 7,
        'digits-date': (v) => v.length >= 10,
        'none': (v) => true,
    }
    
    const validations = {
        '21' : {
            firstName: {
                rule: 'not-empty',
                msg: 'Имя не может быть пустым',
            },
            lastName: {
                rule: 'not-empty',
                msg: 'Фамилия не может быть пустой',
            },
            secondName: {
                rule: 'not-empty',
                msg: 'Отчество не может быть пустым',
            },
            documentSeries: {
                rule: 'none',
                msg: 'Серия документа неверно заполнена',
            },
            documentNumber: {
                rule: 'none',
                msg: 'Номер документа неверно заполнен',
            },
            issueDate: {
                rule: 'none',
                msg: 'Дата выдачи неверно заполнена',
            },
            divisionCode: {
                rule: 'none',
                msg: 'Код подразделения неверно заполнен',
            },
            issuedBy: {
                rule: 'none',
                msg: 'Кем выдан не может быть пустым',
            },
            adress: {
                rule: 'none',
                msg: 'Адрес регистрации не может быть пустым',
            },
            registrationDate: {
                rule: 'none',
                msg: 'Дата регистрации неверно заполнена',
            }
        },
        '03' : {
            firstName: {
                rule: 'not-empty',
                msg: 'Имя не может быть пустым',
            },
            lastName: {
                rule: 'not-empty',
                msg: 'Фамилия не может быть пустой',
            },
            secondName: {
                rule: 'not-empty',
                msg: 'Отчество не может быть пустым',
            },
            documentSeries: {
                rule: 'none',
                msg: 'Серия документа неверно заполнена',
            },
            documentNumber: {
                rule: 'none',
                msg: 'Номер документа неверно заполнен',
            },
            adress: {
                rule: 'none',
                msg: 'Адрес регистрации не может быть пустым',
            },
            registrationDate: {
                rule: 'none',
                msg: 'Дата регистрации неверно заполнена',
            },
            issueDate: {
                rule: 'none',
                msg: 'Дата выдачи неверно заполнена',
            },
            divisionCode: {
                rule: 'none',
                msg: 'Код подразделения неверно заполнен',
            },
            issuedBy: {
                rule: 'none',
                msg: 'Кем выдан не может быть пустым',
            },
        },
        '115' : {
            firstName: {
                rule: 'not-empty',
                msg: 'Имя не может быть пустым',
            },
            lastName: {
                rule: 'not-empty',
                msg: 'Фамилия не может быть пустой',
            },
            secondName: {
                rule: 'not-empty',
                msg: 'Отчество не может быть пустым',
            },
            documentSeries: {
                rule: 'none',
                msg: 'Серия документа неверно заполнена',
            },
            documentNumber: {
                rule: 'none',
                msg: 'Номер документа неверно заполнен',
            },
            adress: {
                rule: 'none',
                msg: 'Адрес регистрации не может быть пустым',
            },
            registrationDate: {
                rule: 'none',
                msg: 'Дата регистрации неверно заполнена',
            },
            issueDate: {
                rule: 'none',
                msg: 'Дата выдачи неверно заполнена',
            },
            divisionCode: {
                rule: 'none',
                msg: 'Код подразделения неверно заполнен',
            },
            issuedBy: {
                rule: 'none',
                msg: 'Кем выдан не может быть пустым',
            },
        },
        'ИП' : {
            firstName: {
                rule: 'not-empty',
                msg: 'Имя не может быть пустым',
            },
            lastName: {
                rule: 'not-empty',
                msg: 'Фамилия не может быть пустой',
            },
            secondName: {
                rule: 'not-empty',
                msg: 'Отчество не может быть пустым',
            },
            documentSeries: {
                rule: 'none',
                msg: 'Серия документа неверно заполнена',
            },
            documentNumber: {
                rule: 'none',
                msg: 'Номер документа неверно заполнен',
            },
            issueDate: {
                rule: 'none',
                msg: 'Дата выдачи неверно заполнена',
            },
            divisionCode: {
                rule: 'none',
                msg: 'Код подразделения неверно заполнен',
            },
            issuedBy: {
                rule: 'none',
                msg: 'Кем выдан не может быть пустым',
            },
            adress: {
                rule: 'none',
                msg: 'Адрес регистрации не может быть пустым',
            },
            registrationDate: {
                rule: 'none',
                msg: 'Дата регистрации неверно заполнена',
            }
        },
        'default' : {
            firstName: {
                rule: 'not-empty',
                msg: 'Имя не может быть пустым',
            },
            lastName: {
                rule: 'not-empty',
                msg: 'Фамилия не может быть пустой',
            },
            secondName: {
                rule: 'not-empty',
                msg: 'Отчество не может быть пустым',
            },
            documentSeries: {
                rule: 'none',
                msg: 'Серия документа неверно заполнена',
            },
            documentNumber: {
                rule: 'none',
                msg: 'Номер документа неверно заполнен',
            },
            issueDate: {
                rule: 'none',
                msg: 'Дата выдачи неверно заполнена',
            },
            divisionCode: {
                rule: 'none',
                msg: 'Код подразделения неверно заполнен',
            },
            issuedBy: {
                rule: 'none',
                msg: 'Кем выдан не может быть пустым',
            },
            adress: {
                rule: 'none',
                msg: 'Адрес регистрации не может быть пустым',
            },
            registrationDate: {
                rule: 'none',
                msg: 'Дата регистрации неверно заполнена',
            }
        },  
    }
    
    const checkRequired = () => {
        const keys = Object.keys(validations[showForm])
        const out = []
        for (const key of keys) {
            if (!rules[validations[showForm][key]['rule']](form[key])) {
                out.push(validations[showForm][key].msg)
            }
        }
        return out
    }

    const onCancel = () => {
        setIsEditing(false);
        resetForm();
    };

    const onSave = () => {
        let errors = checkRequired()
        errors = []
        if (errors.length > 0) {
            setErrorsMsgs(errors)
            errorsMsgsRef.current.scrollIntoView(true, {behavior:'smooth', block: "start", inline: "nearest"})
            return
        }
        setValidate(id, true)
        setErrorsMsgs([])
        updateForm();
        setIsEditing(false);
        setShowForm(form.documentType)
        SoapRequest(urlParams.get('uuid'), urlParams.get('hotel'), urlParams.get('lang'), 'setData', {firstName: form.firstName,
            lastName: form.lastName,
            secondName: form.secondName,
            birthDate: form.birthDate,
            country: form.country,
            documentType: form.documentType,
            documentSeries: form.documentSeries,
            documentNumber: form.documentNumber,
            issueDate: form.issueDate,
            validUntil: form.validUntil,
            divisionCode: form.divisionCode,
            issuedBy: form.issuedBy,
            adress: form.adress,
            registrationDate: form.registrationDate,
            photo1: form.photo1,
            photo1_filename: form.photo1_filename,
            registrationPhoto: form.registrationPhoto,
            registrationPhoto_filename: form.registrationPhoto_filename,
            guestCode: guestCode,
            guestGroup: guestGroup,
            reservationCode: reservationCode,}, 'file_test.php');
        
        
    };

    let countryset = COUNTRIES.find((el) => el.value === form.country.toUpperCase())
    if (!isEditing) {
        return <div className={styles.guest}>
            <p className={styles.editButtonWrapper}>
                {title}
                <span
                    className={styles.editButton}
                    onClick={() => setIsEditing(true)}
                >Изменить</span>
            </p>
            <p>{form.lastName} {form.firstName} {form.secondName}, {countryset && countryset.title}</p>
            <p>{TYPES.find((el) => el.value === showForm).title}</p>
            <br/>
            <p className={styles.roomRateDescription}>{roomRateDescription}</p>
            <p>{roomTypeDescription}</p>
            <p>{accommodationType}</p>
            <p>{"Период проживания: " + checkInDate + " - " + checkOutDate}</p>

        </div>;
    }

    return <div ref={errorsMsgsRef} className={styles.guest}>
        <p>{title}</p>
        <div className={styles.errorsMsgs}>
            {errorsMsgs.map((item) => <p key={item}>{item}</p>)}
        </div>
        <p className='mt-12'>Личная информация</p>
        <div className="row">
            <Input
                placeholder="Фамилия"
                value={form.lastName}
                onChange={lastNameHandler}
                isRequired
            />
            <Input
                placeholder="Имя"
                value={form.firstName}
                onChange={firstNameHandler}
                isRequired
            />
            <Input
                placeholder="Отчество"
                value={form.secondName}
                onChange={secondNameHandler}
                isRequired
            />
        </div>
        <div className="row">
            <Input
                placeholder="Дата рождения"
                value={form.birthDate}
                onChange={birthDateHandler}
                mask="date"
            />
            <Dropdown
                label="Страна"
                renderItem={(item) => item.title}
                value={form.country}
                setValue={countryHandler}
                data={COUNTRIES}
            />
        </div>
        <p className="mt-12">Документ гостя</p>
        <div className="row">
            <Dropdown
                label="Тип документа"
                renderItem={(item) => item.title}
                value={form.documentType}
                setValue={documentTypeHandler}
                data={TYPES}
            />
        </div>
        {showForm === '21' && <>
            <div className="row">
                <Input
                    placeholder="Серия"
                    value={form.documentSeries}
                    onChange={documentSeriesHandler}
                    mask="passport_series"
                    
                />
                <Input
                    placeholder="Номер"
                    value={form.documentNumber}
                    onChange={documentNumberHandler}
                    mask="passport_number"
                    
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Дата выдачи"
                    value={form.issueDate}
                    onChange={issueDateHandler}
                    mask="date"
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Код подразделения"
                    value={form.divisionCode}
                    onChange={divisionCodeHandler}
                    mask="code"
                />
            </div>
            <div className="row">
                <TextArea
                    placeholder="Кем выдан"
                    value={form.issuedBy}
                    onChange={issuedByHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Адрес регистрации"
                    value={form.adress}
                    onChange={adressHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Дата регистрации"
                    value={form.registrationDate}
                    onChange={registrationDateHandler}
                    mask="date"
                />
            </div>
            <p className='mt-12'>Фото страницы #1</p>
            <UploadImageButton onUpload={photo1Handler} filename={form.photo1_filename} setFilename={photo1_filenameHandler} />
            <p className='mt-12'>Фото страницы #5</p>
            <UploadImageButton onUpload={registrationPhotoHandler} filename={form.registrationPhoto_filename} setFilename={registrationPhoto_filenameHandler} />
        </>}
        {showForm === 'ИП' && <>
            <div className="row">
                <Input
                    placeholder="Серия"
                    value={form.documentSeries}
                    onChange={documentSeriesHandler}
                />
                <Input
                    placeholder="Номер"
                    value={form.documentNumber}
                    onChange={documentNumberHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Дата выдачи"
                    value={form.issueDate}
                    onChange={issueDateHandler}
                    mask="date"

                />
                <Input
                    placeholder="Действует до"
                    value={form.validUntil}
                    onChange={validUntilHandler}
                    mask="date"
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Код подразделения"
                    value={form.divisionCode}
                    onChange={divisionCodeHandler}
                />
            </div>
            <div className="row">
                <TextArea
                    placeholder="Кем выдан"
                    value={form.issuedBy}
                    onChange={issuedByHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Адрес регистрации"
                    value={form.adress}
                    onChange={adressHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Дата регистрации"
                    value={form.registrationDate}
                    onChange={registrationDateHandler}
                    mask="date"
                />
            </div>
            <p className='mt-12'>Фото страницы #1</p>
            <UploadImageButton onUpload={photo1Handler} filename={form.photo1_filename} setFilename={photo1_filenameHandler} />
            <p className='mt-12'>Фото страницы #5</p>
            <UploadImageButton onUpload={registrationPhotoHandler} filename={form.registrationPhoto_filename} setFilename={registrationPhoto_filenameHandler} />
        </>}
        {showForm === 'default' && <>
            <div className="row">
                <Input
                    placeholder="Серия"
                    value={form.documentSeries}
                    onChange={documentSeriesHandler}
                />
                <Input
                    placeholder="Номер"
                    value={form.documentNumber}
                    onChange={documentNumberHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Дата выдачи"
                    value={form.issueDate}
                    onChange={issueDateHandler}
                    mask="date"

                />
                <Input
                    placeholder="Действует до"
                    value={form.validUntil}
                    onChange={validUntilHandler}
                    mask="date"
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Код подразделения"
                    value={form.divisionCode}
                    onChange={divisionCodeHandler}
                />
            </div>
            <div className="row">
                <TextArea
                    placeholder="Кем выдан"
                    value={form.issuedBy}
                    onChange={issuedByHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Адрес регистрации"
                    value={form.adress}
                    onChange={adressHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Дата регистрации"
                    value={form.registrationDate}
                    onChange={registrationDateHandler}
                    mask="date"
                />
            </div>
            <p className='mt-12'>Фото страницы #1</p>
            <UploadImageButton onUpload={photo1Handler} filename={form.photo1_filename} setFilename={photo1_filenameHandler} />
            <p className='mt-12'>Фото страницы #5</p>
            <UploadImageButton onUpload={registrationPhotoHandler} filename={form.registrationPhoto_filename} setFilename={registrationPhoto_filenameHandler} />
        </>}
        {showForm === '03' && <>
            <div className="row">
                <Input
                    placeholder="Серия"
                    value={form.documentSeries}
                    onChange={documentSeriesHandler}
                />
                <Input
                    placeholder="Номер"
                    value={form.documentNumber}
                    onChange={documentNumberHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Адрес регистрации"
                    value={form.adress}
                    onChange={adressHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Дата регистрации"
                    value={form.registrationDate}
                    onChange={registrationDateHandler}
                    mask="date"
                />
            </div>
            <p className='mt-12'>Фото свидетельства</p> 
            <UploadImageButton onUpload={photo1Handler} filename={form.photo1_filename} setFilename={photo1_filenameHandler} />
        </>}
        {showForm === '115' && <>
            <div className="row">
                <Input
                    placeholder="Серия"
                    value={form.documentSeries}
                    onChange={documentSeriesHandler}
                />
                <Input
                    placeholder="Номер"
                    value={form.documentNumber}
                    onChange={documentNumberHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Адрес регистрации"
                    value={form.adress}
                    onChange={adressHandler}
                />
            </div>
            <div className="row">
                <Input
                    placeholder="Дата регистрации"
                    value={form.registrationDate}
                    onChange={registrationDateHandler}
                    mask="date"
                />
            </div>
            <p className='mt-12'>Фото свидетельства</p> 
            <UploadImageButton onUpload={photo1Handler} filename={form.photo1_filename} setFilename={photo1_filenameHandler} />
        </>}
        <div className={styles.actionButtons}>
            <span onClick={onCancel} className='mt-12'>Отменить</span>
            <span onClick={onSave} className='mt-12'>Сохранить</span>
        </div>
    </div>;
}

export default GuestDataForm;