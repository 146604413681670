import React, { useState } from 'react';

import styles from './index.module.css';

const Requisites = (props) => {
   
    const [isEditing, setIsEditing] = useState(false);

    if (!isEditing) {
        return <div>
            <p className={styles.editButtonWrapper}>
                <span
                    className={styles.editButton}
                    onClick={() => setIsEditing(true)}
                >{props.title}</span>
            </p>
        </div>;
    }

    return <div>
        <p className={styles.editButtonWrapper}>
            <span
                className={styles.editButton}
                onClick={() => setIsEditing(false)}
            >{props.title}</span>
        </p>
        {props.children}
    </div>;
}

export default Requisites;